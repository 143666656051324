.homeContainer {
  height: 100%;
  width: 100%;
  margin-top: 0;
  background-color: var(--background-color);
}

.mainBorders {
  z-index: 11;
}

.lineVerticle {
  position: absolute;
  height: 100%;
  width: 0.05vw;
  background-color: var(--border-color);
}

.lineHorizontal {
  position: absolute;
  height: 0.05vw;
  width: 100%;
  background-color: var(--border-color);
}

.lineVerticleLeft {
  left: 1.9vw;
  top: 0;
}

.lineVerticleRight {
  right: 1.9vw;
  top: 0;
}

.lineHorizontalTop {
  top: 1.9vw;
  left: 0;
}

.lineHorizontalBottom {
  bottom: 1.9vw;
  left: 0;
}

.gradientBorders {
  z-index: 1;
}

.horizontalGradientBorder {
  height: 1.9vw;
  width: 92vw;
  backdrop-filter: blur(10px);
  background-color: var(--background-gradient-color);
  margin-left: 5vw;
  position: absolute;
}

.horizontalGradientBorderTop {
  top: 0;
  left: 0;
}

.horizontalGradientBorderBottom {
  bottom: 0;
  left: 0;
}

.innerSections {
  position: absolute;
  /* height: calc(100% - 6vw); */
  height: 100%;
  width: 96.1vw;
  /* margin: 3vw 3vw; */
  margin-left: 1.9vw;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.bioSection {
  width: 30vw;
  height: calc(100% - 6vw);
  border-right: 0.05vw solid var(--border-color);
  margin-top: 3vw;
}

.projectsSection {
  width: 66vw;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin-right: 0;
}

.projectsSection::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.leftSection {
  /* margin-top: -50px; */
  /* opacity: 1; */
  /* transform: translateY(50px); */
  /* transition: 0.7s; */
}

.rightSection {
  width: 100%;
}

@keyframes SectionAnimation {
  50% {
    opacity: 0;
    transform: translateY(-50px);
  }
  50.99% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.leftSectionAnimation {
  animation: SectionAnimation 1.3s;
  animation-fill-mode: forwards;
}

.rightSectionAnimation {
  animation: SectionAnimation 1.3s;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
}

@media (max-width: 768px) {
  .innerSections {
    flex-direction: column;
    height: auto;
    background-color: var(--background-color);
    margin: 0;
    width: 100vw;
    padding: 10px 10px;
  }
  .bioSection {
    width: 94vw;
    border-right: none;
  }
  .projectsSection {
    width: 94vw;
  }
  .mainBorders {
    display: none;
  }
  .horizontalGradientBorder {
    display: none;
  }
}
