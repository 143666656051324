.bioArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 6vw);
}

.bioHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1.8vw 2.5vw;
}

.menuIconGroup {
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  height: calc(0.5vw + 0.8vw);
  width: calc(0.5vw + 0.8vw);
  z-index: 71;
}

.menuIcon {
  height: 25px;
  cursor: pointer;

}

@keyframes menuHoverAnimation {
  50% {
    transform: scale(1.3);
  }
}

// @for $i from 1 through 3 {
//   .menuIconCircle:nth-child(#{$i}) {
//     animation-delay: 200ms * $i;
//     animation-name: menuHoverAnimation;
//     animation-fill-mode: forwards;
//   }
// }

.menuIconCircle {
  height: 0.5vw;
  width: 0.5vw;
  border-radius: 15px;
  background-color: var(--foreground-color);
  position: absolute;
  animation-duration: 2s;
  transition: 0.5s;
}

@for $i from 1 through 3 {
  .menuIconCircleAnimation:nth-child(#{$i}) {
    animation-delay: 200ms * $i;
    animation-name: menuHoverAnimation;
    // animation-fill-mode: forwards;
  }
}

// .menuIconCircleAnimation {
//   animation: menuHoverAnimation;
// }

.menuIconCircleTop {
  margin-bottom: 0.5vw;
  top: 0;
  left: 0;
}

.menuIconCircleLeft {
  margin-right: 0.5vw;
  top: calc(0.5vw + 0.5vw);
}

.menuIconCircleRight {
  margin-right: 0.5vw;
  top: calc(0.5vw + 0.5vw);
  left: calc(0.5vw + 0.5vw);
}

.menuArea {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.menu {
  background-color: var(--background-gradient-color);
  border: 1px solid var(--border-color);
  position: absolute;
  top: 2vw;
  z-index: 31;
  transition: 0.4s;
  padding-left: 15px;
  padding-right: 15px;
}

.menuItem {
  font-family: "PPNeueMedium";
  margin-top: 5px;
  font-size: 1.2vw;
  min-width: 12vw;
  cursor: pointer;
}

.menuClose {
  height: 0;
  width: 0;
  border: none;
}

.menuItemList {
  opacity: 0;
  transition: 0.4s;
  margin-top: 1vw;
}

.menuItemListVisible {
  opacity: 1;
  margin-top: 2vw;
}

.menuOpen {
  height: 7vw;
  width: 14vw;
  // padding-top: 3.6vw;
}

.nameAndDiscord {
  padding: 0 2.5vw;
  width: 100%;
}

.ownName {
  font-size: 1.6vw;
  font-family: "PPNeueMedium";
  color: var(--foreground-color);
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.designation {
  font-family: "PPNeueMedium";
  color: var(--foreground-color);
  font-size: 1.1vw;
  margin-top: 0.5vw;
}

.about {
  font-family: "PPNeueRegular";
  color: var(--foreground-color);
  font-size: 1.1vw;
  margin-top: 0.5vw;
  margin-top: 2.5vw;
  line-height: 1.6vw;
  letter-spacing: .1px;
}

.skillsArea {
  width: 100%;
  // padding: 3.2vw 3.2vw;
  padding: 2.5vw 2.5vw 0 2.5vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  // max-height: 350px;
  position: relative;
}

.skillsGradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    var(--background-color) 0%,
    var(--background-color) 35%,
    rgba(0, 212, 255, 0) 100%
  );
  width: 100%;
  height: 7vw;
  position: absolute;
  transition: 0.2s;
}

.skillsGradientTop {
  top: 3.5vw;
  left: 0;
}

.skillsGradientBottom {
  bottom: -1px;
  left: 0;
  transform: rotate(180deg);
}

.sectionHeading {
  font-size: .8vw;
  font-family: "PPNeueMedium";
  color: rgb(123, 123, 123);
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: .1vw;
}

.skillsList {
  padding-top: 1vw;
  padding-bottom: 7vw;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5vw;
  row-gap: 0.7vw;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.skillsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.skillsItem {
  // background-color: var(--background-button-color);
  // color: var(--foreground-color);
  border-radius: 7px;
  /* padding: 11px 36px; */
  padding: 0.7vw 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  font-family: RobotoMonoMedium;
  font-size: 0.7vw;
  /* margin-right: 9px; */
  // border: .2vw solid #ffeaa7;
  // background-color: #ffeaa71a;
}

.bioSectionBackBtn {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 7px;
  padding: 0.7vw 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-family: RobotoMonoMedium;
  margin-right: 0.5vw;
  font-size: 0.7vw;
  cursor: pointer;
  height: 9.8vw;
  margin-right: 8px;
}

.linksArea {
  width: 100%;
  padding: 0 2.5vw 3.2vw 2.5vw;
  max-height: 100%;
  z-index: 99;
  display: none;
}

.linksAreaBio {
  width: 100%;
  padding: 0 2.5vw 3.2vw 2.5vw;
  max-height: 100%;
  z-index: 99;
}

.linkList {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  margin-top: 1vw;
}

.linkItem {
  background-color: var(--background-color);
  // border: .2vw solid var(--border-color);
  border-radius: 7px;
  padding: 0.7vw 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  font-family: RobotoMonoMedium;
  margin-right: 0.5vw;
  font-size: 0.7vw;
  cursor: pointer;
}

.themeSwitchBtnArea {
  margin-left: 2vw;
  height: calc(100%);
  aspect-ratio: 1/1;
  cursor: pointer;
}

.themeSwitch {
  height: 100%;
  width: 100%;
}

.menuButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuBtn {
  font-family: RobotoMonoMedium;
  font-size: .8vw;
  border-right: 0.05vw solid var(--border-color);
  padding-right: 1vw;
  cursor: pointer;
  position: relative;

  &:hover {
		.button-stroke:nth-child(2) {
			stroke-dashoffset: 0;
		}
	}
}

.button-stroke {
	display: block;
	width: calc(100% - 40px);
	height: 20px;
	stroke: transparent;
	position: absolute; 
	left: 15px;
	bottom: -15px;
	stroke-width: 4;
	
	&:nth-child(2) {
		stroke-dasharray: 650px;
		stroke-dashoffset: 650px;
		stroke: darken(#263238, 10%);
		stroke-width: 2;
		transition: stroke-dashoffset 800ms ease-out;
	}
}

.menuBtn:not(:first-child) {
  padding-left: 1vw;
  border-right: none;
}

@media (max-width: 768px) {
  .sectionHeading {
    display: none;
  }
  .bioHeader {
    // margin-top: 20px;
  }
  .menuIconCircle {
    height: 2.2vw;
    width: 2.2vw;
  }
  .menuIconCircleTop {
    margin-bottom: 2vw;
    top: 0;
    left: 0;
  }
  .menuIconCircleLeft {
    margin-right: 0.5vw;
    top: 6vw;
  }
  .menuIconCircleRight {
    margin-right: 0.5vw;
    top: 6vw;
    left: 6vw;
  }
  .ownName {
    font-size: 6.5vw;
    letter-spacing: normal;
    margin-top: 4vw;
  }
  .designation {
    font-size: 4.8vw;
    margin-top: 2px;
  }
  .about {
    font-size: 5vw;
    margin-top: 7vw;
    line-height: 7vw;
  }
  .menuIconGroup {
    height: 12vw;
  }
  .bioHeader {
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .skillsArea {
    display: none;
  }
  .linksArea {
    display: flex;
    padding-top: 8vw;
    padding-bottom: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .linksAreaBio {
    display: flex;
    padding-top: 8vw;
    padding-bottom: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .linkItem {
    padding: 2.8vw 8vw;
    font-size: 3.2vw;
    // border: 1px solid var(--border-color);
    margin-right: 8px;
  }
  @keyframes menuHoverAnimation {
    50% {
      transform: scale(1.2);
    }
  }
  .menuArea {
    position: inherit;
  }
  .menu {
    margin-top: 140px;
    left: 1px;
    backdrop-filter: blur(0px);
    background-color: var(--background-gradient-color);
  }
  .menuOpen {
    height: 70vw;
    width: 60vw;
    border: 1px solid var(--border-color);
  }
  .menuItemList {
    margin-top: 22vw;
  }
  .menuItem {
    font-size: 6vw;
  }
  .menuOpen {
    padding-left: 10px;
    padding-right: 10px;
  }
  .themeSwitchBtnArea {
    height: 10vw;
    margin-bottom: 4vw;
  }
  .menuBtn {
    font-size: 3vw;
    padding-right: 4vw;
  }
  .menuBtn:not(:first-child) {
    padding-left: 4vw;
  }
}
