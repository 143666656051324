@font-face {
  font-family: "PPNeueBold";
  src: url("../public//fonts//PPNeueMontreal-Bold.otf");
}

@font-face {
  font-family: "PPNeueMedium";
  src: url("../public//fonts//PPNeueMontreal-Medium.otf");
}

@font-face {
  font-family: "PPNeueRegular";
  src: url("../public//fonts//PPNeueMontreal-Book.otf");
}

@font-face {
  font-family: "RobotoMonoRegular";
  src: url("../public//fonts//RobotoMono-Regular.ttf");
}

@font-face {
  font-family: "RobotoMonoLight";
  src: url("../public//fonts//RobotoMono-Light.ttf");
}

@font-face {
  font-family: "RobotoMonoMedium";
  src: url("../public//fonts//RobotoMono-Medium.ttf");
}

body,
html,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
}

* {
  box-sizing: border-box;
  transition: 0.2s;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--foreground-color);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

:root {
  --background-color: #fff;
  --foreground-color: #313131;
  --border-color: #d1d1d1;
  --background-gradient-color: #ffffff64;
  --background-button-color: #f3f3f3;
}

[data-theme="dark"] {
  --background-color: #1c1c1c;
  --foreground-color: #ffffff;
  --border-color: #393939;
  --background-gradient-color: #1c1c1c64;
  --background-button-color: #0c0c0c;
}
