.projectArea {
  width: 66vw;
  height: auto;
  padding: calc(2.5vw + 2vw) 2.5vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.projectScreenshotArea {
  width: 66vw;
  height: auto;
  padding: calc(8.5vw + 2vw) 2.5vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.projectItem {
  width: 100%;
  /* min-height: 500px; */
  max-height: 70vh;
  aspect-ratio: 3/2;
  background-color: var(--background-color);
  margin-bottom: 3.2vw;
  object-fit: contain;
  /* border: 0.05vw solid var(--border-color); */
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.projectItemTitleHoverUnderline {
  height: 2px;
  width: 0px;
  background-color: var(--foreground-color);
  transform: 2s;
}

@keyframes underlineAnimation {
  0% {
    width: 0%;
    float: left;
  }
  16.66% {
    width: 20%;
    float: left;
  }
  33.32%{
    width: 100%;
    float: left;
  }
  49.98%{
    width: 100%;
    float: right;
  }
  66.64% {
    width: 20%;
    float: right;
  }
  100% {
    width: 0%;
    float: right;
  }
}

.projectItem:hover .projectItemTitleHoverUnderline{
  /* width: 100%; */
  animation: underlineAnimation 4s infinite steps(2);
  animation-timing-function: ease;
}

.projectItem:hover .projectItemImageArea {
  -webkit-box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.63);
-moz-box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.63);
box-shadow: 0px 7px 20px -2px rgba(0,0,0,0.63);
}

.projectItem:last-child {
  margin-bottom: 0px;
}

.projectItemImageArea {
  height: 82%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 7px;
  -webkit-box-shadow: 0px 7px 20px -9px rgba(0,0,0,1);
-moz-box-shadow: 0px 7px 20px -9px rgba(0,0,0,1);
box-shadow: 0px 7px 20px -9px rgba(0,0,0,1);
}

.projectItemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectItemDetailArea {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0 3.2vw; */
}

.projectItemTitle {
  font-size: 1.9vw;
  font-family: "PPNeueMedium";
  cursor: pointer;
  color: var(--foreground-color);
  margin: 5px 0;
}

.projectItemType {
  font-size: 1.3vw;
  font-family: "PPNeueRegular";
  color: rgb(123, 123, 123);
}

.projectItemActionBtnList {
  display: flex;
  flex-direction: row;
}

.projectItemActionBtn {
  background-color: var(--background-color);
  border: 0.05vw solid var(--border-color);
  border-radius: 7px;
  padding: 0.7vw 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  font-family: RobotoMonoMedium;
  margin-right: 0.5vw;
  font-size: 0.7vw;
  cursor: pointer;
}

.screenshotItem {
  width: 100%;
  /* min-height: 500px; */
  max-height: 70vh;
  aspect-ratio: 16/9;
  background-color: var(--background-color);
  margin-bottom: 3.2vw;
  object-fit: contain;
  /* border: 0.05vw solid var(--border-color); */
  display: flex;
  flex-direction: column;
}

.screenshotImageArea {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  border-radius: 7px
}

.screenshotSectionBackBtn {
  padding: 1vw;
  height: 2.8vw;
  width: 2.8vw;
  min-height: 2.8vw;
  min-width: 2.8vw;
  background-color: var(--background-gradient-color);
  border: 0.05vw solid var(--border-color);
  backdrop-filter: blur(10px);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  font-family: RobotoMonoMedium;
  font-size: 0.7vw;
  position: sticky;
  top: 6.2vw;
  left: 3.3vw;
  cursor: pointer;
}

.actionBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4vw;
  position: fixed;
  top: 5vw;
  margin-left: 0.75vw;
}

.linksSection {
  position: sticky;
  top: 6.2vw;
  left: 3.3vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: .1vw;
}

.linkBtn {
  padding: 0.5vw;
  background-color: var(--background-gradient-color);
  border: 0.05vw solid var(--border-color);
  backdrop-filter: blur(10px);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: fit-content; */
  font-family: RobotoMonoMedium;
  font-size: 0.7vw;
  color: var(--foreground-color);
  /* margin-bottom: 4vw; */
  min-height: 2.8vw;
  padding: 0 2vw;
  cursor: pointer;
  margin-left: .5vw;
}

.expandBtn {
  position: absolute;
  right: 1vw;
  top: 1vw;
  padding: 0.1vw;
  background-color: var(--background-gradient-color);
  border: 0.05vw solid var(--border-color);
  backdrop-filter: blur(10px);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  font-family: RobotoMonoMedium;
  font-size: 0.7vw;
}

.expandIcon {
  height: 1.2vw;
}

@media (max-width: 768px) {
  .projectItemTitleHoverUnderline {
    display: none;
  }
  .actionBtns {
    display: none;
  }
  .projectArea {
    width: 94vw;
  }
  .projectScreenshotArea {
    width: 94vw;
  }
  .projectItemTitle {
    font-size: 6vw;
    margin: 20px 0;
  }
  .projectItemType {
    display: none;
  }
  .projectItemDetailArea {
    flex-direction: column;
    align-items: start;
  }
  .projectItemActionBtnList {
    padding: 0 0 20px 0;
    display: none;
  }
  .projectItemActionBtn {
    padding: 2.8vw 8vw;
    font-size: 2.8vw;
  }
  .screenshotSectionBackBtn {
    padding: 1.5vw;
    font-size: 3.2vw;
    border: 1px solid var(--border-color);
    margin-bottom: 8vw;
  }
}
